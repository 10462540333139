import api from '@api'
import {
  transformSupportData,
  transformWorkflowForServer,
  transformWorkflowForList,
  transformWorkflow,
  // transformModuleNameForServer,
} from '@data/workflow'

import { getRootTranslator } from '@utils/get-module-translator'
import {
  buildRelationalQualificationStructure,
  buildFlatQualificationStructure,
} from '@data/qualification'

const __rootT = getRootTranslator()

export function getSupportDataApi(model, id) {
  return api
    .get(`${model}${id ? `/${id}` : ''}/workflow/supportedData`)
    .then(transformSupportData)
}

export function createWorkflowApi(moduleName, workflow) {
  return api.post(
    `${moduleName}${workflow.parentId ? `/${workflow.parentId}` : ''}/workflow`,
    transformWorkflowForServer(workflow),
    {
      message: __rootT('created_successfully', {
        resource: __rootT('workflow'),
      }),
    }
  )
}

export function getWorkflowApi(moduleName, id) {
  return api
    .get(`${moduleName}/workflow/${id}`)
    .then((data) => transformWorkflow(data))
}

const buildQualifications = (qualificationFactors) => {
  const quals = []
  if (qualificationFactors.name) {
    quals.push(
      buildRelationalQualificationStructure(
        'name',
        'contains',
        qualificationFactors.name,
        'string',
        'db'
      )
    )
  }
  if (qualificationFactors.filterType) {
    quals.push(
      buildRelationalQualificationStructure(
        'disabled',
        'equal',
        qualificationFactors.filterType !== 'enabled',
        'boolean',
        'db'
      )
    )
  }
  return buildFlatQualificationStructure(quals)
}

export function getWorkflowsApi(qualificationFactors, limit, offset) {
  return api
    .post(
      `/${qualificationFactors.moduleName}${
        qualificationFactors.parentId ? `/${qualificationFactors.parentId}` : ''
      }/workflow/search/byqual`,
      { qualDetails: buildQualifications(qualificationFactors) },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformWorkflowForList),
        total: data.totalCount,
      }
    })
}

export function updateWorkflowApi(moduleName, data) {
  return api.patch(
    `${moduleName}/workflow/${data.id}`,
    transformWorkflowForServer(data),
    {
      message: __rootT('saved_successfully', {
        resource: __rootT('workflow'),
      }),
    }
  )
}

export function bulkUpdateWorkflowApi(moduleName, items) {
  const payload = {}
  items.forEach((i) => {
    payload[i.id] = { objOrder: i.order }
  })
  return api.patch(`${moduleName}/workflow/bulk/update`, payload, {
    message: __rootT('saved_successfully', {
      resource: __rootT('workflow'),
    }),
  })
}

export function toggleEnableApi(moduleName, id, data) {
  return api
    .patch(`${moduleName}/workflow/${id}`, data, {
      message: __rootT('updated_successfully', {
        resource: __rootT('workflow'),
      }),
    })
    .then((data) => transformWorkflowForList(data))
}

export function deleteWorkflowApi(moduleName, id) {
  return api.delete(`${moduleName}/workflow/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: __rootT('workflow'),
    }),
  })
}
