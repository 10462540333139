<template>
  <div>
    <!-- <a @click="$emit('done')"> -->
    <MPopover
      v-if="moduleName === $constants.REQUEST && !supportPortalPrint"
      ref="popoverRef"
      placement="bottomRight"
      :overlay-style="{ zIndex: 9990 }"
      overlay-class-name="form-overlay"
      @show="handleOpen"
    >
      <template v-slot:trigger>
        <slot name="trigger">
          <div>
            <MIcon name="print" class="action-menu-icon" />
            <span class="ml-1 action-menu-text">
              {{ $t('print') }}
            </span>
          </div>
        </slot>
      </template>
      <MRow :gutter="0">
        <MCol>
          <MRow>
            <MCol>
              <h6 class="text-primary">
                <slot name="heading">
                  {{ `${$tc('select')} ${$tc('print')} ${$tc('template')}` }}
                </slot>
              </h6>
            </MCol>
          </MRow>
          <FlotoForm layout="vertical" @submit="handleSubmit">
            <PrintTemplatePicker
              v-model="data.printTemplateId"
              :module-name="$constants.REQUEST"
              mandatory
              as-input
              :service-catalog-id="serviceCatalogId"
              only-enabled-templates-allowed
              :allow-clear="false"
              :overlay-style="{ zIndex: 9999 }"
              overlay-class-name="form-overlay"
            />
            <template v-slot:submit="{ submit }">
              <div class="flex justify-end mt-2">
                <MButton
                  variant="primary"
                  :loading="processing"
                  rounded
                  class="mr-2"
                  @click="submit"
                >
                  {{ $t('print') }}
                </MButton>
                <MButton variant="default" rounded @click="hide">
                  {{ $t('cancel') }}
                </MButton>
              </div>
            </template>
          </FlotoForm>
        </MCol>
      </MRow>
    </MPopover>
    <a
      v-else
      :href="supportPortalPrint ? printUrlForRequestSupportPortal : printUrl"
      target="_blank"
    >
      <MIcon name="print" class="action-menu-icon" />
      <span class="ml-1 action-menu-text">
        {{ $t('print') }}
      </span>
    </a>
  </div>
</template>

<script>
import PrintTemplatePicker from '@components/data-picker/print-template-picker'
import { authComputed } from '../../../../state/modules/auth'
export default {
  name: 'Print',
  components: { PrintTemplatePicker },
  props: {
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
    serviceCatalogId: { type: Number, required: true },
    supportPortalPrint: { type: Boolean, default: false },
  },
  data() {
    return {
      processing: false,
      data: {},
    }
  },
  computed: {
    ...authComputed,
    printUrl() {
      if (this.accessToken) {
        return `/api/analytics/download/print/${this.moduleName}/${[
          this.resourceId,
        ]}
          ?mid=${this.accessToken}`
      }
      return undefined
    },
    printUrlForRequest() {
      if (this.accessToken) {
        return `/api/analytics/download/print/template/${this.moduleName}/${[
          this.resourceId,
        ]}
          ?templateId=${this.data.printTemplateId}&mid=${this.accessToken}`
      }
      return undefined
    },
    printUrlForRequestSupportPortal() {
      if (this.accessToken) {
        return `/api/analytics/cportal/download/print/${this.moduleName}/${this.resourceId}?mid=${this.accessToken}`
      }
      return undefined
    },
  },
  methods: {
    handleOpen() {
      this.data = {
        printTemplateId: 0,
      }
    },
    handleSubmit() {
      this.processing = true
      if (this.printUrlForRequest) {
        window.open(this.printUrlForRequest, '_blank')
        this.hide()
        this.$emit('trigger')
      }
    },
    hide() {
      this.$refs.popoverRef && this.$refs.popoverRef.hide()
      this.data = {}
      this.processing = false
      this.$emit('trigger')
    },
  },
}
</script>
